<template>
  <div class="evaluateStudy">
    <div class="header">
      <i class="el-icon-alifanhui" @click="back"></i>
      <div>操作评价</div>
      <i></i>
    </div>
    <van-tree-select :items="items" :main-active-index.sync="activeIndex" @click-item="clickItem" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      activeIndex: 0,
      projectID: "",
    }
  },
  methods: {
    // 获取数据
    initData() {
      this.$axios.get('/api/Evaluate/GetTeaEvaluate', "").then(res => {
        var data = JSON.parse(res.data);
        console.log(data);
        if (data.code == 200) {
          this.items = data.data;
        }
      });
    },
    // 点击左上角返回
    back() {
      this.$router.push({ name: "teacherhome" });
    },
    // 导航右侧数据
    clickItem(data) {
      // 被点击项的数据
      this.cookies.kkSet("typeTable", data.Type);
      this.cookies.kkSet("evaluateID", data.EvaluateID);
      console.log(data.Type)
      this.$router.push({ name: 'teaevaluateTable' })
    },
  },
  mounted() {
    this.projectID = this.cookies.kkGet("projectID");
    this.initData();
  },
}
</script>

<style>
.evaluateStudy .header {
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  color: #101010;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  background: #f8f8f8;
  z-index: 999;
}
.evaluateStudy .header i {
  margin: 0 18px;
  font-size: 20px;
  display: block;
  width: 30px;
}
.evaluateStudy .van-icon {
  font-weight: bold;
}
.evaluateStudy .van-tree-select {
  margin-top: 44px;
  height: calc(100vh - 45px) !important;
}
.evaluateStudy .headerImg {
  width: 18px;
  height: 19px;
}
.evaluateStudy .headerImg img {
  width: 100%;
  height: 100%;
  margin-top: 2px;
}
.evaluateStudy .van-tree-select__nav {
  background-color: #fff;
  max-width: 120px;
}
.evaluateStudy .van-sidebar-item {
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
  box-sizing: border-box;
}
.evaluateStudy .van-sidebar-item--select,
.van-sidebar-item--select:active {
  background: #f3f9fd;
  color: #3294ff;
}
.evaluateStudy .van-tree-select__content {
  background: #f3f9fd;
  padding: 0 10px 10px;
}
.evaluateStudy .van-tree-select__item {
  background: #fff;
  margin-top: 10px;
  border-radius: 4px;
  height: 41px;
  line-height: 41px;
  border: 1px solid #dddddd;
}
.evaluateStudy .van-tree-select__item--active {
  border: 1px solid #0067c0;
  background: #e4f3ff;
  border-radius: 4px;
  color: #0067c0;
}
.evaluateStudy .van-sidebar-item--select::before {
  display: none;
}
</style>